/*************************************** Assign Key page *****************************************/

import {
  React,
  useEffect,
  axios,
  useState,
  swal,
  Dialog,
  DialogContent,
  FadeLoader,
  MaterialTable,
  ExportPdf,
  ExportCsv,
  DialogTitle,
  TextField,
  Button,
} from "../common/Import";

import { BiSolidUserCheck, CgClose } from "../common/Icons";

const AssignKey = () => {
  const [usedStates, setUsedStates] = useState({
    loading: false,

    disable: false,

    allUnAssignedKeys: [],

    assignModal: {
      open: false,
      assignKeyDetails: {
        skmUserId: window.sessionStorage.getItem("skmUserId"),
        userRole: window.sessionStorage.getItem("role"),
        serialKey: "",
        assignEmail: "",
        assignTo: "",
        contactNo: "",
        branch: "",
        zone: "",
        remark: "",
        notifyEmails: "",
      },
    },

    errors: {
      assignEmailError: "",
      assignToError: "",
      contactNoError: "",
      branchError: "",
    },
  });

  /******************************** Destructering objects start ********************/

  const { loading, allUnAssignedKeys, assignModal, errors, disable } =
    usedStates;

  const { assignKeyDetails, open } = assignModal;

  const {
    skmUserId,
    assignEmail,
    assignTo,
    contactNo,
    branch,
    zone,
    remark,
    serialKey,
    userRole,
  } = assignKeyDetails;

  const { assignEmailError, assignToError, contactNoError, branchError } =
    errors;

  /******************************** Destructering objects end ********************/

  // get all assgined keys based on SKM userId
  const getAllKeyDetails = async ({ skmUserId, userRole }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAllUnAssignKeys", {
        params: { skmUserId, userRole },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allUnAssignedKeys: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // all Key columns
  const allKeyColumns = [
    {
      title: "S.No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "SerialKey",
      field: "SerialKey",
      width: "14%",
    },

    {
      title: "Status",
      field: "Status",
    },
  ];

  // handle input event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        assignModal: {
          ...prevValue.assignModal,
          assignKeyDetails: {
            ...prevValue.assignModal.assignKeyDetails,
            [name]: value,
          },
        },
      };
    });
  };

  // handle Reset Errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  // handle reset modal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,

        loading: false,
        disable: false,

        assignModal: {
          ...prevValue.assignModal,
          open: false,
          assignKeyDetails: {
            ...prevValue.assignModal.assignKeyDetails,
            serialKey: "",
            assignEmail: "",
            assignTo: "",
            contactNo: "",
            branch: "",
            zone: "",
            remark: "",
            notifyEmails: "",
          },
        },

        errors: {
          ...prevValue.errors,
          assignEmailError: "",
          assignToError: "",
          contactNoError: "",
          branchError: "",
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
          disable: true,
        };
      });

      const result = await axios.patch(
        "/updateUnAssignKeyDetails",
        assignKeyDetails
      );

      // on success
      if (result.data.success) {
        handleResetModal();

        swal({
          title: "Success",
          icon: "success",
          text: `Serial Key [${serialKey}] has been assigned to ${assignTo}.`,
        }).then((value) => {
          if (value) {
            getAllKeyDetails({ skmUserId, userRole });
          }
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
          disable: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const { assignToError, assignEmailError, contactNoError, branchError } =
          errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              assignToError,
              assignEmailError,
              contactNoError,
              branchError,
            },
          };
        });
      }
      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  useEffect(() => {
    //load intelinput to contact No
    getAllKeyDetails({ skmUserId, userRole });
  }, [skmUserId, userRole]);

  return (
    <>
      <section className="assign-key-section" style={{ marginTop: "4rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <MaterialTable
                    title={<h5 className="fw-bold">Assign keys to user</h5>}
                    columns={allKeyColumns}
                    data={allUnAssignedKeys}
                    localization={{
                      header: {
                        actions: "Assign Key",
                      },
                      pagination: {
                        labelRowsPerPage: "",
                      },
                    }}
                    options={{
                      padding: "dense",
                      exportAllData: true,
                      pageSize: 100,
                      pageSizeOptions: [10, 20, 50, 100],
                      headerStyle: {
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        background: "var(--glass-border)",
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 400,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },

                      exportMenu: [
                        {
                          label: "Export PDF",
                          exportFunc: (cols, datas) =>
                            ExportPdf(cols, datas, "AssignKey-list"),
                        },
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "AssignKey-list"),
                        },
                      ],
                    }}
                    // other props
                    actions={[
                      {
                        icon: () => (
                          <BiSolidUserCheck
                            color="var(--success-color)"
                            className="shadow"
                            size={25}
                          />
                        ),

                        tooltip: "Assign Serial Key",

                        onClick: (event, rowData) => {
                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              assignModal: {
                                ...prevValue.assignModal,
                                open: true,
                                assignKeyDetails: {
                                  ...prevValue.assignModal.assignKeyDetails,
                                  serialKey: rowData.SerialKey,
                                  notifyEmails: rowData.notifyEmails,
                                },
                              },
                            };
                          });
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>

      {/* Assign Dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className="d-flex flex-row justify-content-between align-items-center bg-gradient text-white"
        >
          <span className="fs-4 fw-bold ">Assign Key Details</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-2">
              {/* SerialKey */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="serialKey"
                  name="serialKey"
                  label="Serial Key"
                  fullWidth
                  type="text"
                  required
                  value={serialKey}
                  className="pe-none"
                />
              </div>

              {/* user Name */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="assignTo"
                  name="assignTo"
                  label="User Name"
                  fullWidth
                  type="text"
                  required
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("assignToError");
                  }}
                  error={assignToError ? true : false}
                  helperText={assignToError}
                  value={assignTo}
                />
              </div>

              {/* Email Id */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="assignEmail"
                  name="assignEmail"
                  label="Email Address"
                  type="email"
                  fullWidth
                  required
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("assignEmailError");
                  }}
                  error={assignEmailError ? true : false}
                  helperText={assignEmailError}
                  value={assignEmail}
                />
              </div>

              {/* contact No */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="contactNo"
                  name="contactNo"
                  label="Contact Number"
                  type="tel"
                  fullWidth
                  required
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("contactNoError");
                  }}
                  error={contactNoError ? true : false}
                  helperText={contactNoError}
                  value={contactNo}
                />
              </div>

              {/* Branch */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="branch"
                  name="branch"
                  label="Branch"
                  fullWidth
                  required
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("branchError");
                  }}
                  error={branchError ? true : false}
                  helperText={branchError}
                  value={branch}
                />
              </div>

              {/* Zone */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="zone"
                  name="zone"
                  label="Zone"
                  fullWidth
                  onChange={handleInputEvent}
                  value={zone}
                />
              </div>

              {/* Remark */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="remark"
                  name="remark"
                  label="Remark"
                  fullWidth
                  onChange={handleInputEvent}
                  value={remark}
                />
              </div>

              {/* submit button */}
              <div className="col-xl-12">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={disable}
                >
                  assign
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignKey;
