/************************** start file  ****************************/

import { React, Router, useEffect, AOS } from "./views/common/Import";

const App = () => {
  useEffect(() => {
    // animation
    AOS.init();
  }, []);

  return <Router />;
};

export default App;
