/*************************************** Monitor ticket page *****************************************/

import { CgClose, IoIosEye } from "./common/Icons";
import {
  React,
  useState,
  useEffect,
  Dialog,
  DialogContent,
  FadeLoader,
  swal,
  axios,
  moment,
  MaterialTable,
  ExportPdf,
  ExportCsv,
  DialogTitle,
  lodash,
} from "./common/Import";

const MonitorTickets = () => {
  const [usedStates, setUsedStates] = useState({
    loading: false,

    allKeyData: [],

    skmUserId: window.sessionStorage.getItem("skmUserId"),
    userRole: window.sessionStorage.getItem("role"),
    serialKey: "",

    allTicketDetails: [],

    ticketModal: {
      open: false,
    },
  });

  /***************** Destructering Objects Start *****************/

  const {
    loading,
    allKeyData,
    skmUserId,
    allTicketDetails,
    ticketModal,
    userRole,
    serialKey,
  } = usedStates;

  const { open } = ticketModal;

  /***************** Destructering Objects end *****************/

  // get all Key Data
  const getAllKeyData = async ({ skmUserId, userRole }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAllKeyData", {
        params: { skmUserId, optionType: "tickets", userRole },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allKeyData: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // allKey columns
  const allKeyColumns = [
    {
      title: "SR No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "SerialKey",
      field: "SerialKey",
    },

    {
      title: "AssignedTo",
      field: "AssignedTo_Name",
      render: (rowData) => {
        return rowData.AssignedTo_Name ? rowData.AssignedTo_Name : "-";
      },
    },

    {
      title: "Contact Number",
      field: "PhoneNumber",
      render: (rowData) => {
        return rowData.PhoneNumber ? rowData.PhoneNumber : "-";
      },
    },

    {
      title: "Branch",
      field: "Branch",
      render: (rowData) => {
        return rowData.Branch ? rowData.Branch : "-";
      },
    },

    {
      title: "Zone",
      field: "Zone",
      render: (rowData) => {
        return rowData.Zone ? rowData.Zone : "-";
      },
    },

    {
      title: "Remark",
      field: "Remark",
      render: (rowData) => {
        return rowData.Remark ? rowData.Remark : "-";
      },
    },
  ];

  // get ticket Details
  const getTicketDetails = async ({ serialKey }) => {
    try {
      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAllticketDetails", {
        params: { serialKey },
      });

      // on success
      if (result.data) {
        //initialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
            allTicketDetails: lodash.uniqBy(result.data, "ComplaintNo"),
            serialKey,
            ticketModal: {
              ...prevValue.ticketModal,
              open: true,
            },
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // ticket columns
  const ticketColumns = [
    {
      title: "SR No",
      field: "Sr_No",
    },

    {
      title: "SerialKey",
      field: "Serial_key",
      hidden: true,
      export: true,
    },

    {
      title: "Complaint DateTime",
      field: "Comp_date_time",
      render: (rowData) => {
        return moment(rowData.Comp_date_time, "YYYY-MM-DD hh:mm:ss a")
          .add(750, "minutes")
          .format("DD/MM/YYYY hh:mm:ss a");
      },
    },

    {
      title: "Action DateTime",
      field: "Action_datetime",
      render: (rowData) => {
        return rowData.Action_datetime
          ? moment(rowData.Action_datetime, "YYYY-MM-DD hh:mm:ss a")
              .add(750, "minutes")
              .format("DD/MM/YYYY hh:mm:ss a")
          : "-";
      },
    },

    {
      title: "Status",
      field: "Status",
      render: (rowData) => {
        return (
          <span
            style={{
              color:
                rowData.Status === "Closed"
                  ? "var(--success-color)"
                  : "var(--red-color)",
            }}
          >
            {rowData.Status}
          </span>
        );
      },
    },

    {
      title: "Ticket No",
      field: "ComplaintNo",
    },

    {
      title: "Problem Faced",
      field: "Problem_faced",
    },

    {
      title: "Solution Provided",
      field: "Solution_provided",
      render: (rowData) => {
        return rowData.Solution_provided ? rowData.Solution_provided : "-";
      },
    },

    {
      title: "User Name",
      field: "Name",
    },

    {
      title: "Contact No",
      field: "Contact",
    },
  ];

  useEffect(() => {
    getAllKeyData({ skmUserId, userRole });
  }, [skmUserId, userRole]);

  return (
    <>
      <section className="view-key-section" style={{ marginTop: "4rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <MaterialTable
                    title={<h5 className="fw-bold">Monitor tickets</h5>}
                    columns={allKeyColumns}
                    data={allKeyData}
                    localization={{
                      header: {
                        actions: "View tickets",
                      },
                      pagination: {
                        labelRowsPerPage: "",
                      },
                    }}
                    options={{
                      padding: "dense",
                      exportAllData: true,
                      pageSize: 100,
                      pageSizeOptions: [10, 20, 50, 100],
                      headerStyle: {
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        background: "var(--glass-border)",
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 410,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },
                      exportMenu: [
                        {
                          label: "Export PDF",
                          exportFunc: (cols, datas) =>
                            ExportPdf(cols, datas, "monitorTicket-list"),
                        },
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) =>
                            ExportCsv(cols, datas, "monitorTicket-list"),
                        },
                      ],
                    }}
                    actions={[
                      {
                        icon: () => (
                          <IoIosEye
                            color="var(--blue-color)"
                            className="shadow"
                            size={25}
                          />
                        ),

                        tooltip: "View",

                        onClick: (event, rowData) => {
                          getTicketDetails({ serialKey: rowData.SerialKey });
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ticket dialog */}
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        style={{ marginTop: "-1.5rem", marginBottom: "-1.5rem" }}
      >
        <DialogTitle
          height={50}
          className="d-flex flex-row justify-content-between align-items-center bg-gradient text-white"
        >
          <span className="fs-4 fw-bold ">Ticket Details </span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  ticketModal: {
                    ...prevValue.ticketModal,
                    open: false,
                  },
                };
              });
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            title={<h5 className="fw-bold">SerialKey - [{serialKey}]</h5>}
            columns={ticketColumns}
            data={allTicketDetails}
            localization={{
              pagination: {
                labelRowsPerPage: "",
              },
            }}
            options={{
              exportAllData: true,
              padding: "dense",
              pageSize: 100,
              pageSizeOptions: [10, 20, 50, 100],
              headerStyle: {
                color: "black",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                position: "sticky",
                zIndex: 100,
                background: "var(--glass-border)",
                backdropFilter: "blur(100px)",
              },
              maxBodyHeight: 410,
              rowStyle: {
                whiteSpace: "nowrap",
              },
              exportMenu: [
                {
                  label: "Export PDF",
                  exportFunc: (cols, datas) => {
                    const newData = datas.map((items) => {
                      return {
                        ...items,
                        Comp_date_time: moment(
                          items.Comp_date_time,
                          "YYYY-MM-DD hh:mm:ss a"
                        )
                          .add(750, "minutes")
                          .format("DD/MM/YYYY hh:mm:ss a"),
                        Action_datetime: moment(
                          items.Action_datetime,
                          "YYYY-MM-DD hh:mm:ss a"
                        )
                          .add(750, "minutes")
                          .format("DD/MM/YYYY hh:mm:ss a"),
                      };
                    });
                    ExportPdf(cols, newData, "Ticket-list");
                  },
                },
                {
                  label: "Export CSV",
                  exportFunc: (cols, datas) => {
                    const newData = datas.map((items) => {
                      return {
                        ...items,
                        Comp_date_time: moment(
                          items.Comp_date_time,
                          "YYYY-MM-DD hh:mm:ss a"
                        )
                          .add(750, "minutes")
                          .format("DD/MM/YYYY hh:mm:ss a"),
                        Action_datetime: moment(
                          items.Action_datetime,
                          "YYYY-MM-DD hh:mm:ss a"
                        )
                          .add(750, "minutes")
                          .format("DD/MM/YYYY hh:mm:ss a"),
                      };
                    });
                    ExportCsv(cols, newData, "Ticket-list");
                  },
                },
              ],
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MonitorTickets;
