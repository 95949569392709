/************************ Routing can handle by this file ************************/

import {
  React,
  Routes,
  Route,
  Login,
  DashBoard,
  PageNotFound,
  ResetPassword,
} from "../views/common/Import";

const Router = () => {
  return (
    <>
      <Routes>
        {/* Login Page */}
        <Route exact path="/" element={<Login />} />

        {/* Welcome */}
        <Route exact path="/welcome" element={<DashBoard />} />

        {/* Assign Keys */}
        <Route exact path="/assign-keys" element={<DashBoard />} />

        {/* Retract Keys */}
        <Route exact path="/retract-keys" element={<DashBoard />} />

        {/* Track Users*/}
        <Route exact path="/track-user" element={<DashBoard />} />

        {/* monitor tickets */}
        <Route exact path="/monitor-tickets" element={<DashBoard />} />

        {/* Notification */}
        <Route exact path="/notification" element={<DashBoard />} />

        {/* reset password */}
        <Route
          exact
          path="/reset-password/:token"
          element={<ResetPassword />}
        ></Route>

        {/* page not found */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default Router;
