/*************************************** Welcome  page *****************************************/

import { React } from "./common/Import";

const Welcome = () => {
  return (
    <>
      <section className="welcome-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-content-center min-vh-100 text-center">
            <div className="col-xl-12">
              <h1>Welcome, {window.sessionStorage.getItem("userName")}</h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Welcome;
