/*************************************** DashBoard page *****************************************/

import {
  Header,
  React,
  Welcome,
  useLocation,
  useEffect,
  useNavigate,
  AssignKey,
  RetractKey,
  TrackUser,
  MonitorTickets,
  Notification,
} from "./Import";

const DashBoard = () => {
  const location = useLocation();

  const navigate = useNavigate();

  // check session
  useEffect(() => {
    if (!window.sessionStorage.getItem("userName")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      {/* header part */}
      <Header />

      {/* content */}
      <div className="content">
        {location.pathname === "/assign-keys" ? (
          <AssignKey />
        ) : location.pathname === "/retract-keys" ? (
          <RetractKey />
        ) : location.pathname === "/track-user" ? (
          <TrackUser />
        ) : location.pathname === "/monitor-tickets" ? (
          <MonitorTickets />
        ) : location.pathname === "/notification" ? (
          <Notification />
        ) : (
          <Welcome />
        )}
      </div>
    </>
  );
};

export default DashBoard;
