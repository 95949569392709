/*************************************** View  Keys page *****************************************/

import { CgClose } from "./common/Icons";
import {
  React,
  useState,
  useEffect,
  Dialog,
  DialogContent,
  FadeLoader,
  swal,
  axios,
  moment,
  MaterialTable,
  ExportPdf,
  ExportCsv,
  DialogTitle,
  Button,
} from "./common/Import";

const TrackUser = () => {
  const [usedStates, setUsedStates] = useState({
    loading: false,

    allKeyData: [],

    specificKeyData: [],

    noOfInstallationData: [],

    skmUserId: window.sessionStorage.getItem("skmUserId"),
    userRole: window.sessionStorage.getItem("role"),
    serialKey: "",

    specificKeyModal: {
      open: false,
      optionType: "",
    },
  });

  /***************** Destructering Objects Start *****************/

  const {
    loading,
    allKeyData,
    specificKeyData,
    skmUserId,
    specificKeyModal,
    noOfInstallationData,
    userRole,
    serialKey,
  } = usedStates;

  const { open, optionType } = specificKeyModal;

  /***************** Destructering Objects end *****************/

  // get all Key Data
  const getAllKeyData = async ({ skmUserId, userRole }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAllKeyData", {
        params: { skmUserId, userRole },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allKeyData: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // allKey columns
  const allKeyColumns = [
    {
      title: "SR No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "SerialKey",
      field: "SerialKey",
    },
    {
      title: "Status",
      field: "Status",
    },

    {
      title: "Assigned DateTime",
      field: "AssignedDate",
      render: (rowData) => {
        return rowData.AssignedDate
          ? moment(rowData.AssignedDate, "YYYY-MM-DD hh:mm:ss a")
              .add(750, "minutes")
              .format("DD/MM/YYYY hh:mm:ss a")
          : "-";
      },
    },

    {
      title: "AssignedTo_Current User",
      field: "AssignedTo_Name",
      render: (rowData) => {
        return rowData.AssignedTo_Name ? rowData.AssignedTo_Name : "-";
      },
    },

    {
      title: "Email Address",
      field: "EmailID",
      render: (rowData) => {
        return rowData.EmailID ? rowData.EmailID : "-";
      },
    },

    {
      title: "Contact Number",
      field: "PhoneNumber",
      render: (rowData) => {
        return rowData.PhoneNumber ? rowData.PhoneNumber : "-";
      },
    },

    {
      title: "Branch",
      field: "Branch",
      render: (rowData) => {
        return rowData.Branch ? rowData.Branch : "-";
      },
    },

    {
      title: "Zone",
      field: "Zone",
      render: (rowData) => {
        return rowData.Zone ? rowData.Zone : "-";
      },
    },

    {
      title: "Used No. of time/History",
      field: "noOfAssign",
      render: (rowData) => {
        return (
          <Button
            className="p-0 m-0"
            style={{ lineHeight: "0" }}
            title="show"
            variant="text"
            disabled={rowData.noOfAssign === 0 ? true : false}
            onClick={() =>
              getSpecificKeyData({
                skmUserId,
                serialKey: rowData.SerialKey,
              })
            }
          >
            {rowData.noOfAssign}
          </Button>
        );
      },
    },

    {
      title: "Last Active",
      field: "LastLoginDateTime",
      render: (rowData) => {
        return rowData.LastLoginDate
          ? moment(rowData.LastLoginDate, "YYYY-MM-DD hh:mm:ss a")
              .add(750, "minutes")
              .format("DD/MM/YYYY hh:mm:ss a")
          : "-";
      },
    },

    {
      title: "No. of times Logged in",
      field: "NoOfLogins",
      render: (rowData) => {
        return rowData.NoOfLogins ? rowData.NoOfLogins : 0;
      },
    },

    {
      title: "No. of times Installation",
      field: "noOfInstallation",
      render: (rowData) => {
        return (
          <Button
            className="p-0 m-0"
            style={{ lineHeight: "0" }}
            title="show"
            variant="text"
            disabled={rowData.noOfInstallation === 0 ? true : false}
            onClick={() =>
              getInstallationData({ serialKey: rowData.SerialKey })
            }
          >
            {rowData.noOfInstallation}
          </Button>
        );
      },
    },

    {
      title: "Remark",
      field: "Remark",
      render: (rowData) => {
        return rowData.Remark ? rowData.Remark : "-";
      },
    },
  ];

  // get specific key data
  const getSpecificKeyData = async ({ skmUserId, serialKey }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getSpecificKeyData", {
        params: { skmUserId, serialKey, userRole },
      });

      // on success
      if (result.data) {
        // intialize variable
        let count = 0;

        // to keep history records reverse is used
        // [because order by is not useful for this scenario]
        result.data.reverse().forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,

            specificKeyData: result.data,

            specificKeyModal: {
              ...prevValue.specificKeyModal,
              open: true,
              optionType: "keyHistory",
            },

            serialKey,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // specificKey columns
  const specificKeyColumns = [
    {
      title: "SR No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "SerialKey",
      field: "SerialKey",
      hidden: true,
      export: true,
    },

    {
      title: "User/AssignedTo",
      field: "AssignedTo_Name",
      render: (rowData) => {
        return rowData.AssignedTo_Name ? rowData.AssignedTo_Name : "-";
      },
    },

    {
      title: "Email Address",
      field: "EmailID",
      render: (rowData) => {
        return rowData.EmailID ? rowData.EmailID : "-";
      },
    },

    {
      title: "Contact Number",
      field: "PhoneNumber",
      render: (rowData) => {
        return rowData.PhoneNumber ? rowData.PhoneNumber : "-";
      },
    },

    {
      title: "Assigned DateTime",
      field: "AssignedDate",
      render: (rowData) => {
        return rowData.AssignedDate
          ? moment(rowData.AssignedDate, "YYYY-MM-DD hh:mm:ss a ")
              .add(750, "minutes")
              .format("DD/MM/YYYY hh:mm:ss a")
          : "-";
      },
    },

    {
      title: "Retracted DateTime",
      field: "RetractedDate",
      render: (rowData) => {
        return rowData.RetractedDate
          ? moment(rowData.RetractedDate, "YYYY-MM-DD hh:mm:ss a")
              .add(750, "minutes")
              .format("DD/MM/YYYY hh:mm:ss a")
          : "-";
      },
    },

    {
      title: "Branch",
      field: "Branch",
      render: (rowData) => {
        return rowData.Branch ? rowData.Branch : "-";
      },
    },

    {
      title: "Zone",
      field: "Zone",
      render: (rowData) => {
        return rowData.Zone ? rowData.Zone : "-";
      },
    },

    {
      title: "Assigned Remark",
      field: "Assigned_Remark",
      render: (rowData) => {
        return rowData.Assigned_Remark ? rowData.Assigned_Remark : "-";
      },
    },

    {
      title: "Retract Remark",
      field: "Remark",
      render: (rowData) => {
        return rowData.Remark ? rowData.Remark : "-";
      },
    },
  ];

  // get installation data
  const getInstallationData = async ({ serialKey }) => {
    try {
      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getInstallationData", {
        params: { serialKey },
      });

      // on success
      if (result.data) {
        // intialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
            specificKeyModal: {
              ...prevValue.specificKeyModal,
              open: true,
              optionType: "installationData",
            },

            noOfInstallationData: result.data,
            serialKey,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // installation columns
  const installationColumns = [
    {
      title: "SR No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "SerialKey",
      field: "SerialKey",
      hidden: true,
      export: true,
    },

    {
      title: "User Name",
      field: "UserName",
      width: "5%",
    },
    {
      title: "Email Address",
      field: "EmailID",
      width: "10%",
    },
    {
      title: "Contact No",
      field: "PhoneNo",
      width: "5%",
    },
    {
      title: "Installed DateTime",
      field: "InstalledDate",
      render: (rowData) => {
        return moment(rowData.InstalledDate, "YYYY-MM-DD hh:mm:ss a")
          .add(750, "minutes")
          .format("DD/MM/YYYY hh:mm:ss a");
      },
    },
  ];

  useEffect(() => {
    getAllKeyData({ skmUserId, userRole });
  }, [skmUserId, userRole]);

  return (
    <>
      <section className="view-key-section" style={{ marginTop: "4rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <MaterialTable
                    title={<h5 className="fw-bold">Track User / View Keys</h5>}
                    columns={allKeyColumns}
                    data={allKeyData}
                    localization={{
                      pagination: {
                        labelRowsPerPage: "",
                      },
                    }}
                    options={{
                      padding: "dense",
                      exportAllData: true,
                      pageSize: 100,
                      pageSizeOptions: [10, 20, 50, 100],
                      headerStyle: {
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        background: "var(--glass-border)",
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 410,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },
                      exportMenu: [
                        {
                          label: "Export PDF",
                          exportFunc: (cols, datas) => {
                            const newData = datas.map((items) => {
                              return {
                                ...items,
                                AssignedDate: items.AssignedDate
                                  ? moment(
                                      items.AssignedDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                                LastLoginDate: items.LastLoginDate
                                  ? moment(
                                      items.LastLoginDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                              };
                            });
                            ExportPdf(cols, newData, "TrackUser-list");
                          },
                        },
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) => {
                            const newData = datas.map((items) => {
                              return {
                                ...items,
                                AssignedDate: items.AssignedDate
                                  ? moment(
                                      items.AssignedDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                                LastLoginDate: items.LastLoginDate
                                  ? moment(
                                      items.LastLoginDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                              };
                            });
                            ExportCsv(cols, newData, "TrackUser-list");
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* specific Key dialog */}
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ marginTop: "-1.5rem", marginBottom: "-1.5rem" }}
      >
        <DialogTitle
          height={50}
          className="d-flex flex-row justify-content-between align-items-center bg-gradient text-white"
        >
          <span className="fs-4 fw-bold ">
            {" "}
            {optionType === "keyHistory"
              ? "SerialKey History"
              : "Installation Activities"}{" "}
          </span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  specificKeyModal: {
                    ...prevValue.specificKeyModal,
                    open: false,
                  },
                };
              });
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <MaterialTable
            title={<h5 className="fw-bold">SerialKey - [{serialKey}]</h5>}
            columns={
              optionType === "keyHistory"
                ? specificKeyColumns
                : installationColumns
            }
            data={
              optionType === "keyHistory"
                ? specificKeyData
                : noOfInstallationData
            }
            localization={{
              pagination: {
                labelRowsPerPage: "",
              },
            }}
            options={{
              exportAllData: true,
              padding: "dense",
              pageSize: 100,
              pageSizeOptions: [10, 20, 50, 100],
              headerStyle: {
                color: "black",
                whiteSpace: "nowrap",
                fontWeight: "bold",
                position: "sticky",
                zIndex: 100,
                background: "var(--glass-border)",
                backdropFilter: "blur(100px)",
              },
              maxBodyHeight: 410,
              rowStyle: {
                whiteSpace: "nowrap",
              },
              exportMenu: [
                {
                  label: "Export PDF",
                  exportFunc: (cols, datas) => {
                    const newData =
                      optionType === "keyHistory"
                        ? datas.map((items) => {
                            return {
                              ...items,
                              AssignedDate: items.AssignedDate
                                ? moment(
                                    items.AssignedDate,
                                    "YYYY-MM-DD hh:mm:ss a"
                                  )
                                    .add(750, "minutes")
                                    .format("DD/MM/YYYY hh:mm:ss a")
                                : "-",
                              RetractedDate: items.RetractedDate
                                ? moment(
                                    items.RetractedDate,
                                    "YYYY-MM-DD hh:mm:ss a"
                                  )
                                    .add(750, "minutes")
                                    .format("DD/MM/YYYY hh:mm:ss a")
                                : "-",
                            };
                          })
                        : datas.map((items) => {
                            return {
                              ...items,
                              InstalledDate: items.InstalledDate
                                ? moment(
                                    items.InstalledDate,
                                    "YYYY-MM-DD hh:mm:ss a"
                                  )
                                    .add(750, "minutes")
                                    .format("DD/MM/YYYY hh:mm:ss a")
                                : "-",
                            };
                          });
                    ExportPdf(
                      cols,
                      newData,
                      optionType === "keyHistory"
                        ? "keyHistory-list"
                        : "installation-list"
                    );
                  },
                },
                {
                  label: "Export CSV",
                  exportFunc: (cols, datas) => {
                    const newData =
                      optionType === "keyHistory"
                        ? datas.map((items) => {
                            return {
                              ...items,
                              AssignedDate: items.AssignedDate
                                ? moment(
                                    items.AssignedDate,
                                    "YYYY-MM-DD hh:mm:ss a"
                                  )
                                    .add(750, "minutes")
                                    .format("DD/MM/YYYY hh:mm:ss a")
                                : "-",
                              RetractedDate: items.RetractedDate
                                ? moment(
                                    items.RetractedDate,
                                    "YYYY-MM-DD hh:mm:ss a"
                                  )
                                    .add(750, "minutes")
                                    .format("DD/MM/YYYY hh:mm:ss a")
                                : "-",
                            };
                          })
                        : datas.map((items) => {
                            return {
                              ...items,
                              InstalledDate: items.InstalledDate
                                ? moment(
                                    items.InstalledDate,
                                    "YYYY-MM-DD hh:mm:ss a"
                                  )
                                    .add(750, "minutes")
                                    .format("DD/MM/YYYY hh:mm:ss a")
                                : "-",
                            };
                          });

                    ExportCsv(
                      cols,
                      newData,
                      optionType === "keyHistory"
                        ? "keyHistory-list"
                        : "installation-list"
                    );
                  },
                },
              ],
            }}
          />
        </DialogContent>
      </Dialog>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrackUser;
