/*************************************** sidebar  page *****************************************/

import {
  React,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useState,
  Collapse,
  useNavigate,
  swal,
  Gradient,
} from "./Import";

import {
  HiUserGroup,
  MdExpandLess,
  MdExpandMore,
  BiSolidUserCheck,
  BiSolidUserMinus,
  PiMonitorFill,
  RiLogoutBoxFill,
  IoIosEye,
  IoIosNotifications,
} from "./Icons";

const SideBar = (props) => {
  const naviagate = useNavigate();

  const [usedStates, setUsedStates] = useState({
    userManagement: false,
  });

  /******************** Destrcutering  objects start ************************/
  const { userManagement } = usedStates;

  /******************** Destrcutering  objects end ************************/

  // handle logut
  const handleLogOut = () => {
    // navigate to login screen
    naviagate("/");

    swal({
      text: "You have been logged out successfully.",
      timer: 2000,
      buttons: false,
      icon: "success",
    }).then(() => {
      window.sessionStorage.clear();
    });
  };

  return (
    <>
      <section className="heading-section text-center bg-gradient">
        <img
          src={require("../../dist/img/user.png")}
          alt="profile.png"
          className="img-fluid mt-3"
          width="60"
          height="60"
        />
        <h1 className="mt-2 mb-2 fw-bold fs-5 text-white ">
          {window.sessionStorage.getItem("userName")}
        </h1>
      </section>

      {/* sidebar section */}
      <section className="sidebar-section mt-1">
        {/*********************  User Management menu start ******************************/}
        <ListItemButton
          data-aos="flip-up"
          data-aos-delay="100"
          onClick={() =>
            setUsedStates((prevValue) => {
              return {
                ...prevValue,
                userManagement: !userManagement,
              };
            })
          }
        >
          <ListItemIcon>
            <Gradient>
              <HiUserGroup
                size={20}
                className="shadow"
                style={{ fill: "url(#gradient)" }}
              />
            </Gradient>
          </ListItemIcon>
          <ListItemText primary="User Management" className="text-muted" />
          {userManagement ? (
            <MdExpandLess
              className="no-hover"
              size={20}
              color="var(--default-color)"
            />
          ) : (
            <MdExpandMore
              className="no-hover"
              size={20}
              color="var(--default-color)"
            />
          )}
        </ListItemButton>

        <Collapse in={userManagement} timeout="auto" unmountOnExit>
          {/* Assign SerialKey */}
          <List
            component="div"
            disablePadding
            data-aos="flip-up"
            data-aos-delay="100"
          >
            <ListItemButton
              onClick={() => {
                naviagate("/assign-keys");

                // close sidebar
                props.closeDrawer();
              }}
            >
              <ListItemIcon>
                <Gradient>
                  <BiSolidUserCheck
                    size={20}
                    className="shadow"
                    style={{ fill: "url(#gradient)" }}
                  />
                </Gradient>
              </ListItemIcon>
              <ListItemText primary="Assign Keys" className="text-muted" />
            </ListItemButton>
          </List>

          {/* Retract SerialKey */}
          <List
            component="div"
            disablePadding
            data-aos="flip-up"
            data-aos-delay="200"
          >
            <ListItemButton
              onClick={() => {
                naviagate("/retract-keys");

                // close sidebar
                props.closeDrawer();
              }}
            >
              <ListItemIcon>
                <Gradient>
                  <BiSolidUserMinus
                    style={{ fill: "url(#gradient)" }}
                    size={20}
                    className="shadow"
                  />
                </Gradient>
              </ListItemIcon>
              <ListItemText primary="Retract Keys" className="text-muted" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* Track Users */}
        <List
          component="div"
          disablePadding
          data-aos="flip-up"
          data-aos-delay="200"
        >
          <ListItemButton
            onClick={() => {
              naviagate("/track-user");

              // close sidebar
              props.closeDrawer();
            }}
          >
            <ListItemIcon>
              <Gradient>
                <IoIosEye
                  style={{ fill: "url(#gradient)" }}
                  size={20}
                  className="shadow"
                />
              </Gradient>
            </ListItemIcon>
            <ListItemText primary="Track Users" className="text-muted" />
          </ListItemButton>
        </List>

        {/* monitor tickets */}
        <List
          component="div"
          disablePadding
          data-aos="flip-up"
          data-aos-delay="300"
        >
          <ListItemButton
            onClick={() => {
              naviagate("/monitor-tickets");

              // close sidebar
              props.closeDrawer();
            }}
          >
            <ListItemIcon>
              <Gradient>
                <PiMonitorFill
                  style={{ fill: "url(#gradient)" }}
                  size={20}
                  className="shadow"
                />
              </Gradient>
            </ListItemIcon>
            <ListItemText primary="Monitor Tickets" className="text-muted" />
          </ListItemButton>
        </List>

        {/*Notification */}
        <List
          component="div"
          disablePadding
          data-aos="flip-up"
          data-aos-delay="400"
        >
          <ListItemButton
            onClick={() => {
              naviagate("/notification");

              // close sidebar
              props.closeDrawer();
            }}
          >
            <ListItemIcon>
              <Gradient>
                <IoIosNotifications
                  style={{ fill: "url(#gradient)" }}
                  size={20}
                  className="shadow"
                />
              </Gradient>
            </ListItemIcon>
            <ListItemText primary="Notifications" className="text-muted" />
          </ListItemButton>
        </List>

        {/* Logout */}
        <List
          component="div"
          disablePadding
          data-aos="flip-up"
          data-aos-delay="500"
        >
          <ListItemButton onClick={() => handleLogOut()}>
            <ListItemIcon>
              <Gradient>
                <RiLogoutBoxFill
                  style={{ fill: "url(#gradient)" }}
                  size={20}
                  className="shadow"
                />
              </Gradient>
            </ListItemIcon>
            <ListItemText primary="Logout" className="text-muted" />
          </ListItemButton>
        </List>
      </section>
    </>
  );
};

export default SideBar;
