/****************************** gradient component *************************/
import { React } from "../common/Icons";

const Gradient = ({ children }) => {
  return (
    <>
      <svg width="0" height="0">
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#006aff" />
          <stop offset="25%" stopColor="#07004d" />
          <stop offset="75%" stopColor="#21B4E2" />
          <stop offset="100%" stopColor="#B7DEED" />
        </linearGradient>
      </svg>

      <div style={{ display: "flex", alignItems: "center" }}>{children}</div>
    </>
  );
};

export default Gradient;
