/*************************************** Notification page *****************************************/

import { MdLibraryAdd } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import {
  React,
  useEffect,
  axios,
  useState,
  swal,
  Dialog,
  DialogContent,
  FadeLoader,
  MaterialTable,
  DialogTitle,
  TextField,
  Button,
  moment,
} from "./common/Import";

import { CgClose } from "./common/Icons";

const Notification = () => {
  const [usedStates, setUsedStates] = useState({
    loading: false,

    disable: false,

    allNotifyDetails: [],

    errors: {
      userNameError: "",
      contactNoError: "",
      notifyEmailError: "",
    },

    userModal: {
      open: false,
      optionType: "",
      notifyDetails: {
        skmUserId: window.sessionStorage.getItem("skmUserId"),
        userRole: window.sessionStorage.getItem("role"),
        userId: "",
        userName: "",
        contactNo: "",
        notifyEmail: "",
        currentEmail: "",
      },
    },
  });

  /************** Destructering object start ***************/

  const { loading, allNotifyDetails, userModal, errors, disable } = usedStates;

  const { open, notifyDetails, optionType } = userModal;

  const { userName, contactNo, notifyEmail, skmUserId, userRole } =
    notifyDetails;

  const { userNameError, contactNoError, notifyEmailError } = errors;

  /************** Destructering object end ***************/

  // get notify Details
  const getNotifyDetails = async ({ skmUserId, userRole }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getNotifyDetails", {
        params: { skmUserId, userRole },
      });

      //on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
            allNotifyDetails: result.data,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // notify columns
  const notifyColumns = [
    {
      title: "S.No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "User Name",
      field: "userName",
    },

    {
      title: "Contact Number",
      field: "contactNo",
    },

    {
      title: "Notify Email",
      field: "notifyEmail",
    },

    {
      title: "Created Date",
      field: "created_date",
      render: (rowData) => {
        return moment(rowData.created_date, "YYYY-MM-DD hh:mm:ss a").format(
          "DD/MM/YYYY hh:mm:ss a"
        );
      },
    },
  ];

  // handle reset modal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,

        loading: false,
        disable: false,

        userModal: {
          ...prevValue.userModal,
          open: false,
          notifyDetails: {
            ...prevValue.userModal.notifyDetails,
            userName: "",
            contactNo: "",
            notifyEmail: "",
            currentEmail: "",
          },
        },

        errors: {
          ...prevValue.errors,
          userNameError: "",
          contactNoError: "",
          notifyEmailError: "",
        },
      };
    });
  };

  // handle input event
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        userModal: {
          ...prevValue.userModal,
          notifyDetails: {
            ...prevValue.userModal.notifyDetails,
            [name]: value,
          },
        },
      };
    });
  };

  // handle reset errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
          disable: true,
        };
      });

      const result =
        optionType === "add"
          ? await axios.post("/storeNotifyDetails", notifyDetails)
          : await axios.patch("/updateNotifyDetails", notifyDetails);

      // on success
      if (result.data.success) {
        handleResetModal();

        swal({
          title: "Success",
          icon: "success",
          text: `Your record ${
            optionType === "add" ? "saved" : "updated"
          }  successfully.`,
        }).then((value) => {
          if (value) {
            getNotifyDetails({ skmUserId, userRole });
          }
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
          disable: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const { userNameError, contactNoError, notifyEmailError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              userNameError,
              contactNoError,
              notifyEmailError,
            },
          };
        });
      }
      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  // handle delete
  const handleDelete = ({ userId }) => {
    swal({
      title: `Are You Sure ?`,
      icon: "warning",
      text: "You won't be able to revert this!",
      buttons: [true, "confirm"],
    }).then(async (value) => {
      try {
        if (value) {
          // enable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              loading: true,
            };
          });

          const result = await axios.delete("/deleteNotifyDetails", {
            params: { userId, skmUserId, userRole },
          });

          // on success
          if (result.data.success) {
            getNotifyDetails({ skmUserId, userRole });
          }
        }
      } catch (err) {
        // disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    });
  };

  useEffect(() => {
    getNotifyDetails({ skmUserId, userRole });
  }, [skmUserId, userRole]);

  return (
    <>
      <section className="notify-section " style={{ marginTop: "4rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <MaterialTable
                    title={<h5 className="fw-bold">Notify Details</h5>}
                    columns={notifyColumns}
                    data={allNotifyDetails}
                    localization={{
                      pagination: {
                        labelRowsPerPage: "",
                      },
                    }}
                    options={{
                      padding: "dense",
                      exportAllData: true,
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 50, 100],
                      headerStyle: {
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        background: "var(--glass-border)",
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 400,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },
                    }}
                    actions={[
                      // create user
                      {
                        icon: () => (
                          <MdLibraryAdd
                            color="var(--success-color)"
                            fontSize={25}
                          />
                        ),
                        tooltip: "Add",
                        isFreeAction: true,
                        onClick: () => {
                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              userModal: {
                                ...prevValue.userModal,
                                open: true,
                                optionType: "add",
                              },
                            };
                          });
                        },
                      },

                      // edit user
                      {
                        icon: () => (
                          <MdEdit color="var(--blue-color)" fontSize={25} />
                        ),
                        tooltip: "Edit",
                        onClick: (event, rowData) => {
                          //get data
                          const { contactNo, userName, notifyEmail, userId } =
                            rowData;

                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              userModal: {
                                ...prevValue.userModal,
                                open: true,
                                optionType: "edit",
                                notifyDetails: {
                                  ...prevValue.userModal.notifyDetails,
                                  userName,
                                  contactNo,
                                  notifyEmail,
                                  currentEmail: notifyEmail,
                                  userId,
                                },
                              },
                            };
                          });
                        },
                      },

                      // delete user
                      {
                        icon: () => (
                          <MdDelete color="var(--error-color)" fontSize={25} />
                        ),
                        tooltip: "Delete",
                        onClick: (event, rowData) => {
                          handleDelete({ userId: rowData.userId });
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>

      {/* Add/ Edit dialog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className="d-flex flex-row justify-content-between align-items-center bg-gradient text-white"
        >
          <span className="fs-4 fw-bold ">Notify Details</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-2">
              {/* user Name */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="userName"
                  name="userName"
                  label="User Name"
                  fullWidth
                  type="text"
                  required
                  value={userName}
                  error={userNameError ? true : false}
                  helperText={userNameError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("userNameError");
                  }}
                />
              </div>

              {/* contact No */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="contactNo"
                  name="contactNo"
                  label="Contact Number"
                  type="tel"
                  fullWidth
                  required
                  value={contactNo}
                  error={contactNoError ? true : false}
                  helperText={contactNoError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("contactNoError");
                  }}
                />
              </div>

              {/* Email Id */}
              <div className="col-xl-12">
                <TextField
                  variant="standard"
                  id="notifyEmail"
                  name="notifyEmail"
                  label="Email Address"
                  type="email"
                  fullWidth
                  required
                  value={notifyEmail}
                  error={notifyEmailError ? true : false}
                  helperText={notifyEmailError}
                  onChange={(event) => {
                    handleInputEvent(event);
                    handleResetErrors("notifyEmailError");
                  }}
                />
              </div>

              {/* submit button */}
              <div className="col-xl-12">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={disable}
                >
                  {optionType === "add" ? "Add" : "update"}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Notification;
