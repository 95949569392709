/*************************************** Header page *****************************************/

import { IoMdMenu } from "./Icons";
import {
  React,
  useState,
  Drawer,
  SideBar,
  useEffect,
  useLocation,
} from "./Import";

const Header = () => {
  const location = useLocation();

  //drawer
  const [open, setOpen] = useState(false);

  const userName = window.sessionStorage.getItem("userName");

  // toggle drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  //open drawer when path is welcome
  useEffect(() => {
    if (location.pathname === "/welcome") {
      setTimeout(() => {
        setOpen(true);
      }, 300);
    }
  }, [location.pathname, userName]);

  return (
    <>
      {/* header section */}
      <header className="header-section fixed-top bg-gradient">
        <div className="container-fluid">
          <div className="row">
            <div className="icon d-flex  justify-content-between">
              {/* hamburger image */}
              <IoMdMenu
                size={30}
                color="white"
                cursor="pointer"
                onClick={() => setOpen(true)}
              />

              {/* company image */}
              <img
                src={require("../../dist/img/Prosoft_Logo.png")}
                alt="Prosoft_Logo.png"
                className="img-fluid"
                width={65}
                height={35}
              />
            </div>
          </div>
        </div>
      </header>

      {/* sidebar */}
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <SideBar closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};

export default Header;
