/*******************************  Page Not found *****************************************************/

import { React, Button, useNavigate } from "./common/Import";

const PageNotFound = () => {
  // used for redirecting page
  const navigate = useNavigate();

  return (
    <>
      {/* error section */}
      <section className="error-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5">
              <div className="card border-0 shadow">
                <div className="card-body text-center">
                  <img
                    src={require("../dist/img/404.png")}
                    alt="404.png"
                    width="100"
                    height="100"
                  />
                  <h1 className="card-title fs-3 fw-bold d-flex flex-column mt-2 ">
                    <span>Page Not found</span>
                  </h1>

                  <p className="mt-3 fs-6 text-muted">
                    The Page you're looking for doesn't exist. it might have
                    been moved or deleted.
                  </p>

                  <Button
                    type="button"
                    className="success-button shadow"
                    onClick={() => navigate("/")}
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
