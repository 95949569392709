/************************** execution starts for this file ****************************/

import { ReactDOM, React, BrowserRouter, App } from "./views/common/Import";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
