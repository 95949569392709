/*********************************** Reset Password page ***************************/
import {
  React,
  useEffect,
  useState,
  jwtDecode,
  swal,
  TextField,
  Button,
  InputAdornment,
  Popover,
  axios,
  useNavigate,
  Dialog,
  DialogContent,
  FadeLoader,
} from "./common/Import";

import { IoInformationCircleSharp } from "react-icons/io5";

const ResetPassword = () => {
  //used for redirecting page
  const navigate = useNavigate();

  const [usedStates, setUsedStates] = useState({
    loading: false,

    resetPasswordDetails: {
      email: "",
      password: "",
      confirmPassword: "",
    },

    errors: {
      passwordError: "",
      confirmPasswordError: "",
    },
  });

  // popover
  const [anchorEl, setAnchorEl] = useState(null);

  /********************** destructering objects start *******************/
  const { loading, resetPasswordDetails, errors } = usedStates;

  const { password, confirmPassword } = resetPasswordDetails;

  const { passwordError, confirmPasswordError } = errors;

  const open = Boolean(anchorEl);

  /********************** destructering objects end *******************/

  // validate User
  const handleValidateUser = () => {
    try {
      //get Email
      const { forgotEmail } = jwtDecode(window.location.pathname.split("/")[2]);

      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          resetPasswordDetails: {
            ...prevValue.resetPasswordDetails,
            email: forgotEmail,
          },
        };
      });
    } catch (err) {
      // display error rather than input errors
      err.response
        ? swal(`${err.response.status}`, `${err.response.data}`, "error")
        : swal(`Error`, `${err}`, "error");
    }
  };

  // handle inputs
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        resetPasswordDetails: {
          ...prevValue.resetPasswordDetails,
          [name]: value,
        },
      };
    });
  };

  // handle reset errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  // handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.patch("/resetPassword", resetPasswordDetails);

      // on success
      if (result.data.success) {
        swal({
          title: "Success",
          icon: "success",
          text: "Your password is updated successfully",
          buttons: false,
          timer: 3000,
        }).then(() => {
          // navigate to welcome page
          navigate("/", { replace: true });
        });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      if (errors) {
        const { passwordError, confirmPasswordError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              passwordError,
              confirmPasswordError,
            },
          };
        });
      }
      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };
  useEffect(() => {
    handleValidateUser();
  }, []);

  return (
    <>
      <section className="reset-section">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center min-vh-100">
            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10">
              <div className="card shadow">
                <div className="card-header bg-gradient text-white">
                  <h1 className="fs-3">Reset Password</h1>
                </div>
                <div className="card-body">
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="row g-2">
                      {/* Password */}
                      <div className="col-xl-12">
                        <TextField
                          required
                          variant="standard"
                          fullWidth
                          name="password"
                          value={password}
                          label="Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IoInformationCircleSharp
                                  aria-owns={
                                    open ? "mouse-over-popover" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-describedby="simple-popover"
                                  size={20}
                                  color="black"
                                  cursor="pointer"
                                  onMouseOver={(event) => {
                                    setAnchorEl(event.currentTarget);
                                  }}
                                  onMouseLeave={() => {
                                    setAnchorEl(null);
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          error={passwordError ? true : false}
                          helperText={passwordError}
                          onChange={(event) => {
                            handleInputEvent(event);
                            handleResetErrors("passwordError");
                          }}
                          type="password"
                        />
                      </div>

                      {/********************************* password info  start  *********************** */}

                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "center",
                        }}
                        onClose={() => setAnchorEl(null)}
                        disableRestoreFocus
                      >
                        <div className="card ">
                          <h2 className="card-header fs-5 fw-bold bg-gradient text-white">
                            The password must include
                          </h2>
                          <div className="card-body">
                            <ul style={{ listStyleType: "circle" }}>
                              <li>length should be minimum 8 characters.</li>
                              <li>atleast one numeric character.</li>
                              <li>atleast one Alphabetic letter.</li>
                              <li>atleast one capital letter.</li>
                              <li>one special character.</li>
                              <li>should not contain white space.</li>
                            </ul>
                          </div>
                        </div>
                      </Popover>

                      {/********************************* password info  end  *********************** */}

                      {/* Confirm password */}
                      <div className="col-xl-12">
                        <TextField
                          required
                          variant="standard"
                          fullWidth
                          name="confirmPassword"
                          value={confirmPassword}
                          label="Confirm Password"
                          error={confirmPasswordError ? true : false}
                          helperText={confirmPasswordError}
                          onChange={(event) => {
                            handleInputEvent(event);
                            handleResetErrors("confirmPasswordError");
                          }}
                          type="password"
                        />
                      </div>

                      {/* submit */}
                      <div className="col-xl-12 mt-3">
                        <Button
                          className="primary-button shadow"
                          variant="outlined"
                          type="submit"
                          disabled={loading}
                        >
                          reset password
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResetPassword;
