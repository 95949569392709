/*************************************** Retract Key page *****************************************/

// Note : 750 minutes is added to convert server Date into Indain standard time.

import {
  MaterialTable,
  React,
  useState,
  useEffect,
  swal,
  axios,
  Dialog,
  DialogContent,
  FadeLoader,
  ExportCsv,
  ExportPdf,
  moment,
  DialogTitle,
  TextField,
  Button,
} from "../common/Import";

import { CgClose, BiSolidUserMinus } from "../common/Icons";

const RetractKey = () => {
  const [usedStates, setUsedStates] = useState({
    loading: false,

    disable: false,

    allAssignedKeys: [],

    retractModal: {
      open: false,

      retractKeyDetails: {
        skmUserId: window.sessionStorage.getItem("skmUserId"),
        userRole: window.sessionStorage.getItem("role"),
        serialKey: "",
        assignEmail: "",
        assignTo: "",
        contactNo: "",
        branch: "",
        zone: "",
        assignedRemark: "",
        retractRemark: "",
        assignedDate: "",
        notifyEmails: "",
      },
    },
  });

  /************************ Destructering Objects start *********************/

  const { loading, allAssignedKeys, retractModal, disable } = usedStates;

  const { open, retractKeyDetails } = retractModal;

  const {
    skmUserId,
    serialKey,
    assignEmail,
    assignTo,
    contactNo,
    branch,
    zone,
    assignedRemark,
    retractRemark,
    assignedDate,
    userRole,
  } = retractKeyDetails;

  /************************ Destructering Objects end *********************/

  // get All assginedKeys
  const getAllAssignedKeys = async ({ skmUserId, userRole }) => {
    try {
      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.get("/getAllAssignedKeys", {
        params: { skmUserId, userRole },
      });

      // on success
      if (result.data) {
        //intialize variable
        let count = 0;

        result.data.forEach((data) => {
          Object.assign(data, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            allAssignedKeys: result.data,
            loading: false,
          };
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  // AssignedKey columns
  const AssignedKeyColumns = [
    {
      title: "S.No",
      field: "Sr_No",
      width: "5%",
    },
    {
      title: "SerialKey",
      field: "SerialKey",
    },

    {
      title: "User/AssignedTo",
      field: "AssignedTo_Name",
    },

    {
      title: "AssignedDateTime",
      field: "AssignedDate",
      render: (rowData) => {
        return moment(rowData.AssignedDate, "YYYY-MM-DD hh:mm:ss a")
          .add(750, "minutes")
          .format("DD/MM/YYYY hh:mm:ss a");
      },
    },

    {
      title: "Email Address",
      field: "EmailID",
    },

    {
      title: "Contact Number",
      field: "PhoneNumber",
    },

    {
      title: "LastLogin DateTime",
      field: "LastLoginDate",
      render: (rowData) => {
        return rowData.LastLoginDate
          ? moment(rowData.LastLoginDate, "YYYY-MM-DD hh:mm:ss a")
              .add(750, "minutes")
              .format("DD/MM/YYYY hh:mm:ss a")
          : "-";
      },
    },
  ];

  // handle Reset modal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,

        loading: false,
        disable: false,

        retractModal: {
          ...prevValue.retractModal,
          open: false,
          retractKeyDetails: {
            ...prevValue.retractModal.retractKeyDetails,
            serialKey: "",
            assignEmail: "",
            assignTo: "",
            contactNo: "",
            branch: "",
            zone: "",
            AssignedRemark: "",
            retractRemark: "",
            assignedDate: "",
            notifyEmails: "",
          },
        },
      };
    });
  };

  // handle Submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
          disable: true,
        };
      });

      const result = await axios.patch("/updateAssignedKey", retractKeyDetails);

      // on success
      if (result.data.success) {
        handleResetModal();

        swal({
          title: "Success",
          icon: "success",
          text: `Serial Key [${serialKey}] retracted successfully.`,
        }).then((value) => {
          // reset data and get new Assigned key data
          if (value) {
            getAllAssignedKeys({ skmUserId, userRole });
          }
        });
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
          disable: false,
        };
      });

      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  };

  useEffect(() => {
    getAllAssignedKeys({ skmUserId, userRole });
  }, [skmUserId, userRole]);

  return (
    <>
      <section className="retract-key-section" style={{ marginTop: "4rem" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card border-0">
                <div className="card-body">
                  <MaterialTable
                    title={<h5 className="fw-bold">Retract keys from user</h5>}
                    columns={AssignedKeyColumns}
                    data={allAssignedKeys}
                    localization={{
                      header: {
                        actions: "Retract Key",
                      },
                      pagination: {
                        labelRowsPerPage: "",
                      },
                    }}
                    options={{
                      padding: "dense",
                      exportAllData: true,
                      pageSize: 100,
                      pageSizeOptions: [10, 20, 50, 100],
                      headerStyle: {
                        color: "black",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        position: "sticky",
                        zIndex: 100,
                        background: "var(--glass-border)",
                        backdropFilter: "blur(100px)",
                      },
                      maxBodyHeight: 410,
                      rowStyle: {
                        whiteSpace: "nowrap",
                      },

                      exportMenu: [
                        {
                          label: "Export PDF",
                          exportFunc: (cols, datas) => {
                            const newData = datas.map((items) => {
                              return {
                                ...items,
                                AssignedDate: items.AssignedDate
                                  ? moment(
                                      items.AssignedDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                                LastLoginDate: items.LastLoginDate
                                  ? moment(
                                      items.LastLoginDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                              };
                            });
                            ExportPdf(cols, newData, "RetractKey-list");
                          },
                        },
                        {
                          label: "Export CSV",
                          exportFunc: (cols, datas) => {
                            const newData = datas.map((items) => {
                              return {
                                ...items,
                                AssignedDate: items.AssignedDate
                                  ? moment(
                                      items.AssignedDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                                LastLoginDate: items.LastLoginDate
                                  ? moment(
                                      items.LastLoginDate,
                                      "YYYY-MM-DD hh:mm:ss a"
                                    )
                                      .add(750, "minutes")
                                      .format("DD/MM/YYYY hh:mm:ss a")
                                  : "-",
                              };
                            });
                            ExportCsv(cols, newData, "RetractKey-list");
                          },
                        },
                      ],
                    }}
                    actions={[
                      {
                        icon: () => (
                          <BiSolidUserMinus
                            color="var(--error-color)"
                            className="shadow"
                            size={25}
                          />
                        ),
                        tooltip: "Retract Serial Key",
                        onClick: (event, rowData) => {
                          //get data
                          const {
                            AssignedTo_Name,
                            Branch,
                            EmailID,
                            PhoneNumber,
                            Remark,
                            Zone,
                            SerialKey,
                            AssignedDate,
                            notifyEmails,
                          } = rowData;

                          setUsedStates((prevValue) => {
                            return {
                              ...prevValue,
                              retractModal: {
                                ...prevValue.retractModal,
                                open: true,
                                retractKeyDetails: {
                                  ...prevValue.retractModal.retractKeyDetails,
                                  serialKey: SerialKey,
                                  assignEmail: EmailID,
                                  assignTo: AssignedTo_Name,
                                  contactNo: PhoneNumber,
                                  branch: Branch,
                                  zone: Zone ? Zone : "-",
                                  assignedRemark: Remark ? Remark : "-",
                                  assignedDate: moment(
                                    AssignedDate,
                                    "YYYY-MM-DD"
                                  ).format("DD/MM/YYYY"),
                                  notifyEmails,
                                },
                              },
                            };
                          });
                        },
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>

      {/* Retract Dialog */}
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ marginTop: "-1rem", marginBottom: "-1rem" }}
      >
        <DialogTitle
          height={50}
          className="d-flex flex-row justify-content-between align-items-center bg-gradient text-white"
        >
          <span className="fs-4 fw-bold ">Retract Key Details</span>
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form method="POST" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row g-1">
              {/* Assigned Date */}
              <div className="col-xl-12">
                <TextField
                  label="Assigned Date"
                  variant="standard"
                  id="assignedDate"
                  name="assignedDate"
                  value={assignedDate}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Serial Key */}
              <div className="col-xl-12">
                <TextField
                  label="Serial Key"
                  variant="standard"
                  id="serialKey"
                  name="serialKey"
                  value={serialKey}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* User Name */}
              <div className="col-xl-12">
                <TextField
                  label="User Name"
                  variant="standard"
                  id="assignTo"
                  name="assignTo"
                  value={assignTo}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Email Address */}
              <div className="col-xl-12">
                <TextField
                  label="Email Address"
                  variant="standard"
                  id="assignEmail"
                  name="assignEmail"
                  value={assignEmail}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Contact No */}
              <div className="col-xl-12">
                <TextField
                  label="Contact No"
                  variant="standard"
                  id="contactNo"
                  name="contactNo"
                  value={contactNo}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Branch */}
              <div className="col-xl-12">
                <TextField
                  label="Branch"
                  variant="standard"
                  id="branch"
                  name="branch"
                  value={branch}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Zone */}
              <div className="col-xl-12">
                <TextField
                  label="Zone"
                  variant="standard"
                  id="zone"
                  name="zone"
                  value={zone}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Assigned Remark */}
              <div className="col-xl-12">
                <TextField
                  label="Assigned Remark"
                  variant="standard"
                  id="assignedRemark"
                  name="assignedRemark"
                  value={assignedRemark}
                  fullWidth
                  className="pe-none"
                />
              </div>

              {/* Retract Remark */}
              <div className="col-xl-12">
                <TextField
                  label="Retract Remark"
                  variant="standard"
                  id="retractRemark"
                  name="retractRemark"
                  value={retractRemark}
                  fullWidth
                  onChange={(event) => {
                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        retractModal: {
                          ...prevValue.retractModal,
                          retractKeyDetails: {
                            ...prevValue.retractModal.retractKeyDetails,
                            retractRemark: event.target.value,
                          },
                        },
                      };
                    });
                  }}
                />
              </div>

              {/* submit button */}
              <div className="col-xl-12 mt-2">
                <Button
                  className="success-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={disable}
                >
                  Retract
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RetractKey;
