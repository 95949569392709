/****************************** Login Page **************************************/

import { CgClose } from "./common/Icons";
import {
  React,
  TextField,
  Button,
  useState,
  swal,
  Dialog,
  DialogContent,
  FadeLoader,
  axios,
  useNavigate,
  DialogTitle,
} from "../views/common/Import";

const Login = () => {
  const navigate = useNavigate();

  const [usedStates, setUsedStates] = useState({
    loading: false,

    loginDetails: {
      userName: "",
      password: "",
    },

    errors: {
      userNameError: "",
      passwordError: "",
      forgotEmailError: "",
    },

    forgotModal: {
      open: false,
      forgotDetails: {
        forgotEmail: "",
      },
    },
  });

  /*************** Destructering object start ******************************/

  const { loading, loginDetails, errors, forgotModal } = usedStates;

  const { userName, password } = loginDetails;

  const { userNameError, passwordError, forgotEmailError } = errors;

  const { open, forgotDetails } = forgotModal;

  const { forgotEmail } = forgotDetails;

  /*************** Destructering object end ******************************/

  // handle inputs
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loginDetails: {
          ...prevValue.loginDetails,
          [name]: value,
        },
      };
    });
  };

  // handle reset errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  //handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.post("/login", loginDetails);

      //on success
      if (result.data) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            loading: false,
          };
        });

        const { skmUserId, role } = result.data;

        // store values in session to use this values in multiple screens
        window.sessionStorage.setItem("skmUserId", skmUserId);
        window.sessionStorage.setItem("role", role);
        window.sessionStorage.setItem("userName", userName);

        // navigate to welcome page
        navigate("/welcome");
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors, loginError } = err.response.data;

      // validation Error
      if (errors) {
        const { userNameError, passwordError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              userNameError,
              passwordError,
            },
          };
        });
      }

      //DB Error
      else if (loginError) {
        swal({ title: "Sorry", icon: "error", text: loginError });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  // handle forgot password
  const handleForgotPassword = async (event) => {
    try {
      event.preventDefault();

      // enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      const result = await axios.post("/forgot-password", forgotDetails);

      // on success
      if (result.data) {
        const { success } = result.data;

        //show success message to user
        swal({ title: "success", text: success, icon: "success" });

        //reset data
        handleResetModal();
      }
    } catch (err) {
      // disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      const { errors } = err.response.data;

      // custom errors
      if (errors) {
        const { forgotEmailError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              forgotEmailError,
            },
          };
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  // handle reset modal
  const handleResetModal = () => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loading: false,
        errors: {
          ...prevValue.errors,
          forgotEmailError: "",
        },
        forgotModal: {
          ...prevValue.forgotModal,
          open: false,
          forgotDetails: {
            forgotEmail: "",
          },
        },
      };
    });
  };

  return (
    <>
      <section className="login-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className=" col-md-10">
              <div className="card h-100 border-0 rounded-0 shadow">
                <div className="card-body p-0 m-0">
                  <div className="row">
                    <div className="col-md bg-gradient d-flex justify-content-center align-items-center">
                      <img
                        src={require("../dist/img/login.png")}
                        alt="login.png"
                        className="img-fluid"
                        width="65%"
                      />
                    </div>

                    {/* login-form */}
                    <div className=" login-form col-md text-center pt-5 mt-5 mb-5 pb-5 ">
                      {/* heading */}
                      <div
                        className="heading"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <h3
                          className="fw-bold"
                          style={{ color: "var(--blue-color)" }}
                        >
                          Login Account
                        </h3>
                        <span className="text-muted">
                          Enter your account details to sign in
                        </span>
                      </div>

                      <div className="login-form mt-3">
                        <form
                          autoComplete="off"
                          method="POST"
                          onSubmit={handleSubmit}
                        >
                          <div className="row g-3">
                            {/* Enter user Name */}
                            <div
                              className="col-xl-12"
                              data-aos="fade-up"
                              data-aos-delay="400"
                            >
                              <TextField
                                variant="standard"
                                id="userName"
                                name="userName"
                                className="w-75"
                                size="small"
                                label="Enter Username"
                                type="text"
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("userNameError");
                                }}
                                error={userNameError ? true : false}
                                helperText={userNameError}
                                value={userName}
                                required
                              />
                            </div>

                            {/* Enter password */}
                            <div
                              className="col-xl-12"
                              data-aos="fade-up"
                              data-aos-delay="500"
                            >
                              <TextField
                                variant="standard"
                                id="password"
                                name="password"
                                className="w-75"
                                size="small"
                                label="Enter Password"
                                type="password"
                                onChange={(event) => {
                                  handleInputEvent(event);
                                  handleResetErrors("passwordError");
                                }}
                                error={passwordError ? true : false}
                                helperText={passwordError}
                                value={password}
                                required
                              />
                            </div>

                            {/* sign in*/}
                            <div className="col-xl-12 text-center">
                              {/* sign in*/}
                              <Button
                                variant="outlined"
                                className="primary-button shadow"
                                type="submit"
                                disabled={loading}
                              >
                                sign in
                              </Button>

                              {/* forgot password*/}
                              <Button
                                variant="outlined"
                                className="forgot-button shadow ms-2"
                                type="button"
                                onClick={() =>
                                  setUsedStates((prevValue) => {
                                    return {
                                      ...prevValue,
                                      forgotModal: {
                                        ...prevValue.forgotModal,
                                        open: true,
                                      },
                                    };
                                  })
                                }
                              >
                                forgot password
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* forogot Dailog */}
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogTitle
          height={50}
          className="d-flex justify-content-end  bg-gradient text-white align-items-center"
        >
          <CgClose
            size={30}
            cursor="pointer"
            onClick={() => {
              handleResetModal();
            }}
          />
        </DialogTitle>
        <DialogContent dividers>
          <form
            method="POST"
            autoComplete="off"
            onSubmit={handleForgotPassword}
          >
            <div className="row g-1">
              {/* Email Address */}
              <div className="col-xl-12">
                <TextField
                  required
                  label="Email Address"
                  variant="standard"
                  id="forgotEmail"
                  name="forgotEmail"
                  value={forgotEmail}
                  fullWidth
                  onChange={(event) => {
                    setUsedStates((prevValue) => {
                      return {
                        ...prevValue,
                        forgotModal: {
                          ...prevValue.forgotModal,
                          forgotDetails: {
                            ...prevValue.forgotModal.forgotDetails,
                            forgotEmail: event.target.value,
                          },
                        },
                      };
                    });

                    handleResetErrors("forgotEmailError");
                  }}
                  error={forgotEmailError ? true : false}
                  helperText={forgotEmailError}
                />
              </div>

              {/* submit button */}
              <div className="col-xl-12 mt-3">
                <Button
                  className="primary-button shadow"
                  variant="outlined"
                  type="submit"
                  disabled={loading}
                >
                  send Link
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* Loading dialog */}
      <Dialog open={loading} className="loading-modal">
        <DialogContent>
          <FadeLoader color="var(--white)" size={25} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Login;
